import { secondsToHHMM } from '@utils/time'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  total?: number
  renderer?: (total: number) => string
}

export const TaskTotal = ({
  className,
  total = 0,
  renderer: render = secondsToHHMM,
}: Props) => {
  return (
    <div
      className={twMerge(
        'text-sm text-neutral-900',
        total > 0 && 'font-bold',
        className,
      )}
    >
      {render(total) || '-'}
    </div>
  )
}
