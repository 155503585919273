import { SideColumn } from 'components/time-entry-table'
import { FC } from 'react'
import { EtcCell } from './cells/etc-cell/EtcCell'

interface Props {
  tasks: SelectedTask[]
}

export const EtcColumn: FC<Props> = ({ tasks }) => {
  return (
    <SideColumn side="right">
      {tasks.map((task, index) => (
        <EtcCell key={task.id} task={task} rowIndex={index} />
      ))}
    </SideColumn>
  )
}
