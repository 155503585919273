import { TFunction } from 'react-i18next'

// Note that order matters here — it's used to order the "silo" elements,
// and to sort statuses. Statuses should be ordered in the order they occur in the state machine.
export enum Status {
  Open = 'open',
  Submitted = 'submitted',
  Approved = 'approved',
  Sent = 'sent_to_payroll',
}

export enum TimeCardIssue {
  UnlinkedProject = 'UNLINKED_PROJECT',
  InactiveProject = 'INACTIVE_PROJECT',
}

export enum WorkdaySendStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export enum DayLockType {
  Unsubmit = 'unsubmit',
  Unapprove = 'unapprove',
  Full = 'full',
}

export { DateTime } from 'luxon'

export type TranslationKey = Parameters<TFunction<'translation', undefined>>[0]

export enum AuditLogTargetType {
  ApprovalGroup = 'approval_group',
  Project = 'project',
  Employee = 'employee',
  TimeCard = 'time_card',
}

export enum AuditLogEditField {
  Name = 'name',
  AutoApproval = 'auto_approval',
  ApprovalGroup = 'approval_group',
  Body = 'body',
  LockType = 'lock_type',
}

export enum AuditLogEventType {
  ApprovalGroupCreated = 'approval_group_created',
  ApprovalGroupRenamed = 'approval_group_renamed',
  ApprovalGroupAutoApprovalChanged = 'approval_group_auto_approval_changed',
  ApprovalGroupDeleted = 'approval_group_deleted',
  ApprovalApproved = 'approval_approved',
  ApprovalUnapproved = 'approval_unapproved',
  EmployeeApprovalGroupChanged = 'employee_approval_group_changed',
  ApproverAdded = 'approver_added',
  ApproverRemoved = 'approver_removed',
  ProjectAutoApprovalChanged = 'project_auto_approval_changed',
  TimeCardSubmitted = 'time_card_submitted',
  TimeCardUnsubmitted = 'time_card_unsubmitted',
  TimeCardCreated = 'time_card_created',
  TimeCardDeleted = 'time_card_deleted',
  TimeCardSentToPayroll = 'time_card_sent_to_payroll',
  TimeLoggingNoteAdded = 'time_logging_note_added',
  TimeLoggingNoteRemoved = 'time_logging_note_removed',
  TimeLoggingNoteUpdated = 'time_logging_note_updated',
  DayLocked = 'day_locked',
  DayUnlocked = 'day_unlocked',
}

export enum TimeLogReportEntryType {
  Worked = 'worked',
  TimeOff = 'time_off',
  Leave = 'leave',
  Holiday = 'holiday',
}

export enum ProjectIntegrationType {
  ShotGrid = 'shotgrid',
  Jira = 'jira',
}

export enum TaskEstimateMode {
  DISABLED = 'disabled',
  BURN_DOWN_ONLY = 'burn_down_only',
  FULL = 'full',
}

export enum TaskEstimateSuppliedValueType {
  ETC = 'etc',
  ETT = 'ett',
}
