import { FC, useState } from 'react'
import { Button } from 'components/buttons'

export const LoginWithOIDC: FC = () => {
  const apiBaseUrl = import.meta.env.VITE_API_BASE_URL
  const [isRedirecting, setIsRedirecting] = useState(false)

  const clickHandler = () => {
    setIsRedirecting(true)
    location.href = `${apiBaseUrl}/auth/oidc`
  }

  return (
    <Button
      onClick={clickHandler}
      className="h-11 text-lg w-full"
      disabled={isRedirecting}
      loading={isRedirecting}
    >
      Login
    </Button>
  )
}
