import { useMutation, useQueryClient } from '@tanstack/react-query'
import { entryKeys, taskEstimateKeys } from '@lib/keys'
import { useUserId } from './useUserId'
import { useWeekDates } from '@hooks/useWeekDates'
import { AxiosResponse } from 'axios'

interface OptimisticMutationInterface {
  queryFunction: ({ entry }: { entry: Entry }) => Promise<Entry | AxiosResponse>
}

/**
 * This hook is specifically used for optimistically mutating entries.
 *
 * In the future, its implementation should head one of two ways:
 *
 * 1: Lean into its specificity, and bring in the query fuction in via a hook
 *    itself (as opposed to the prop).
 *
 * 2: Be made more generic; exposing callbacks that'll allow for query invalidation
 *    to be performed by the caller (for example)
 *
 * - AF 1/13/23
 */
function useOptimisticMutation(props: OptimisticMutationInterface) {
  const userId = useUserId()
  const { start, end } = useWeekDates()

  const key = entryKeys.search(userId, start, end)

  const queryClient = useQueryClient()
  return useMutation(props.queryFunction, {
    onMutate: async (vars) => {
      const entry = vars.entry

      // Cancel any outgoing refetches (so they don't overwrite our update)
      await queryClient.cancelQueries(entryKeys.all)

      // Snapshot the previous entries
      const previousEntries = queryClient.getQueryData<Entry[]>(key)

      // Optimistically update query cache to the new value if this is an update
      if (previousEntries) {
        const otherEntries = previousEntries.filter(
          (e) =>
            e.task.id !== entry.task.id || e.timeCard.id !== entry.timeCard.id,
        )

        // Add the new entry back in if it has a duration,
        // otherwise we're about to delete it, so simply exclude it
        if (entry.duration > 0) {
          queryClient.setQueryData(key, [...otherEntries, entry])
        } else {
          queryClient.setQueryData(key, otherEntries)
        }
      }

      // Return a context with the previous and new entry
      return { previousEntries, entry }
    },
    onError: async (_error, _variables, context) => {
      if (context) {
        // Failed request — roll back if we can
        if (context.previousEntries) {
          queryClient.setQueryData<Entry[]>(key, [...context.previousEntries])

          // Invalidate query to fetch new data anyway,
          // in case prior value differs from persisted value
          // (e.g. user rapidly changed input,
          // and debouncing behavior caused only last change to make network request, which failed)
          await queryClient.invalidateQueries(key)
        }
      } else {
        // Error onMutate, before we return context
        await queryClient.invalidateQueries(entryKeys.all)
      }
    },
    onSuccess: (_data, variables) => {
      // Latest task testimate includes total time logged,
      // so invalidate on any entry update
      void queryClient.invalidateQueries(
        taskEstimateKeys.latestForTask(variables.entry.task.id),
      )
    },
    onSettled: async () => {
      /**
       * Commenting out since I don't think we need to invalidate on the happy path.
       * Doing so created a "jolt" between old and new state that I couldn't
       * navigate around expediently
       *
       * - AF 1/11/23
       */
      // await queryClient.invalidateQueries(key)
    },
  })
}

export { useOptimisticMutation }
