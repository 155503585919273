import { Button } from 'components/buttons'
import {
  EntryInput,
  IncrementDecrementButtons,
} from 'components/time-entry-table'
import { parse } from './utils/parse'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { Bars } from './Bars'
import { renderDaysHoursMinutes } from './utils/renderDuration'
import { TaskEstimateMode } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

const ONE_DAY = 8 * 60 * 60

interface Props {
  disableSave: boolean
  estimated?: number // seconds
  estimateMode: TaskEstimateMode
  heading: string
  logged?: number // seconds
  onCancel: () => void
  onSave: (value: number /* seconds */) => void
  subHeading?: string
  subtitle?: string
  title: string
}

export const EtcCard: FC<Props> = ({
  disableSave,
  estimated,
  estimateMode,
  heading,
  logged,
  onCancel,
  onSave,
  subHeading,
  subtitle,
  title,
}) => {
  const { t } = useTranslation()
  const [updatedEstimate, setUpdatedEstimate] = useState(
    renderDaysHoursMinutes(
      estimated === undefined ? undefined : estimated - (logged ?? 0),
    ),
  )

  const parsed = parse(updatedEstimate)
  const isValid =
    estimateMode !== TaskEstimateMode.FULL ||
    (parsed !== undefined && parsed >= 0)

  const increment = () => {
    let newTime = parsed ?? 0

    // If current value is evenly divisible by a whole day,
    // increase by 1 day, otherwise round up to the nearest day
    if (newTime % ONE_DAY === 0) {
      newTime += ONE_DAY
    } else {
      newTime = Math.ceil(newTime / ONE_DAY) * ONE_DAY
    }

    setUpdatedEstimate(renderDaysHoursMinutes(newTime))
  }

  const decrement = () => {
    let newTime = parsed ?? 0

    // If current value is evenly divisible by a whole day,
    // decrease by 1 day, otherwise round down to the nearest day
    if (newTime % ONE_DAY === 0) {
      newTime -= ONE_DAY
    } else {
      newTime = Math.floor(newTime / ONE_DAY) * ONE_DAY
    }

    setUpdatedEstimate(renderDaysHoursMinutes(Math.max(newTime, 0)))
  }

  return (
    <div className="flex flex-col w-[360px] rounded-lg shadow border border-neutral-200 mb-10">
      <div className="flex flex-col bg-neutral-100 px-3 py-2 text-neutral-900">
        <div className="flex flex-row text-sm">
          <span className="font-semibold">{title}</span>
          {subtitle && <span className="ml-1">({subtitle})</span>}
        </div>
        {subHeading && (
          <div className="flex flex-row items-center h-6 text-xs text-neutral-600 font-medium">
            {subHeading}
          </div>
        )}
        {heading && (
          <div className="flex flex-row items-center h-6 text-xs font-semibold">
            {heading}
          </div>
        )}{' '}
      </div>
      <div className="flex flex-col p-3 gap-y-2 border-b border-neutral-200">
        <div className="text-xs text-neutral-600 font-medium">
          {t('components.timeEntryTable.taskProgress')}
        </div>
        <Bars
          disableEstimate={estimateMode !== TaskEstimateMode.FULL}
          etc={isValid ? parsed : undefined}
          estimateSuffix={
            estimateMode === TaskEstimateMode.BURN_DOWN_ONLY ? '*' : ''
          }
          logged={logged}
        />
        <div className="text-xs text-neutral-600 font-medium mt-1">
          {t('components.timeEntryTable.estimateToComplete')}
        </div>
        <div className="flex flex-row items-center">
          <div
            className={twMerge(
              'flex flex-row w-3/4 p-2 mr-2 border border-neutral-200 rounded-lg',
              estimateMode !== TaskEstimateMode.FULL && 'bg-neutral-150',
            )}
          >
            <IncrementDecrementButtons
              disabled={
                estimateMode !== TaskEstimateMode.FULL
                  ? 'both'
                  : (parsed ?? 0) <= 0
                  ? 'decrement'
                  : undefined
              }
              onIncrement={increment}
              onDecrement={decrement}
            />
            <EntryInput
              disabled={estimateMode !== TaskEstimateMode.FULL}
              placeHolder={t(
                'features.timeLogging.estimatePlaceholder',
              ).toString()}
              className="outline-none"
              onIncrement={increment}
              onDecrement={decrement}
              value={updatedEstimate}
              setValue={setUpdatedEstimate}
            />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'flex flex-row justify-end p-3 gap-x-2',
          estimateMode !== TaskEstimateMode.FULL &&
            estimated === undefined &&
            'bg-warning-100',
        )}
      >
        {estimateMode === TaskEstimateMode.FULL ? (
          <>
            <Button onClick={onCancel} variant="outlined">
              {t('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                if (!isValid) return
                if (parsed === undefined) return

                onSave(parsed)
              }}
              disabled={!isValid || disableSave}
            >
              {t('common.save')}
            </Button>
          </>
        ) : (
          <span
            className={twMerge(
              'text-xs font-medium flex flex-row gap-1 items-center',
              estimated === undefined ? 'text-warning-900' : 'text-neutral-600',
            )}
          >
            {estimated === undefined ? (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-warning-900"
              />
            ) : (
              '*'
            )}
            {t('components.timeEntryTable.etcsCanOnlyBeSetInShotgrid')}
          </span>
        )}
      </div>
    </div>
  )
}
