import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  className?: string
}

export const SectionHeading: FC<Props> = ({ children, className }) => {
  return (
    <h2
      className={twMerge(
        'text-lg leading-none text-neutral-900 mb-3',
        className,
      )}
    >
      {children}
    </h2>
  )
}
