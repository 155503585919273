import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { TimeWidget } from 'components/form/time-widget'
import { FC, ReactNode, useCallback, useRef, useState } from 'react'
import { GridCell } from './GridCell'
import { Display } from './Display'
import { useSettings } from '@hooks/useSettings'
import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { MealCheckbox } from './MealCheckbox'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

interface TimeWidgetContainerProps {
  children: ReactNode
  hidden: boolean
  onClose: () => void
}

const TimeWidgetContainer: FC<TimeWidgetContainerProps> = ({
  children,
  hidden,
  onClose,
}) => {
  const ref = useRef(null)
  useOnClickOutside(ref, onClose)

  if (hidden) return null

  return (
    <div
      className={[
        'absolute right-0 top-7 w-[360px] drop-shadow-lg z-20',
        'will-change-[filter]', // Fix for shadow persisting after element is removed from DOM (hidden) on Safari
      ].join(' ')}
      ref={ref}
    >
      {children}
    </div>
  )
}

const getInitialIsMeal = (
  obj: Pick<TimeAndDurationVariantProps, 'isMeal' | 'isMealByDefault'> | object,
) => {
  if (!('isMeal' in obj)) return false

  return obj.isMeal || obj.isMealByDefault
}

type BaseProps = {
  autoFocusedTimeWidgetAdjuster: 'start' | 'end'
  editable: boolean
  endAtSecondsSinceMidnight: number | null
  fieldPrefix?: string
  formattedContents: string
  isError?: boolean
  onHoverLeave?: () => void
  onHoverOver?: () => void
  rowIndex: number
  startAtSecondsSinceMidnight: number | null
  testId?: string
  timeZone: string
} & ({ date: DateTime } | { day: DayOfWeek })

type TimeVariantProps = BaseProps & {
  onSubmit: (
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
  ) => void
  variant: 'time'
}

type TimeAndDurationVariantProps = BaseProps & {
  isMeal: boolean
  isMealByDefault: boolean
  onSubmit: (
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
    isMeal: boolean,
  ) => void
  showIsMealCheckbox: boolean
  variant: 'time-and-duration'
}

export type WorkDayCellProps = TimeVariantProps | TimeAndDurationVariantProps

export const WorkDayCell: FC<WorkDayCellProps> = ({
  autoFocusedTimeWidgetAdjuster,
  editable,
  endAtSecondsSinceMidnight,
  fieldPrefix,
  formattedContents,
  isError,
  onHoverOver,
  onHoverLeave,
  onSubmit,
  rowIndex,
  startAtSecondsSinceMidnight,
  testId,
  timeZone,
  variant,
  ...props
}) => {
  const [timeWidgetHidden, setTimeWidgetHidden] = useState(true)
  const useSettingsQueryResult = useSettings()
  const { registerMenuClosed, registerMenuOpened } =
    useInhibitKeyPressOverride()
  const isInitiallyMeal = getInitialIsMeal(props)
  const [isMeal, setIsMeal] = useState(isInitiallyMeal)
  const { t } = useTranslation()

  const close = useCallback(() => {
    registerMenuClosed('time-widget')
    setTimeWidgetHidden(true)
  }, [setTimeWidgetHidden, registerMenuClosed])

  const open = () => {
    registerMenuOpened('time-widget')
    setTimeWidgetHidden(false)
  }

  const date = 'date' in props ? props.date : DateTime.now()

  return (
    <GridCell
      {...('date' in props ? { date } : { day: props.day })}
      editable={editable}
      isError={isError}
      rowIndex={rowIndex}
      onMouseMove={onHoverOver}
      onMouseLeave={onHoverLeave}
      onClick={() => editable && open()}
      className={twMerge('relative', editable && 'cursor-pointer')}
      testId={testId}
    >
      <TimeWidgetContainer
        hidden={timeWidgetHidden}
        onClose={() => {
          close()
          setIsMeal(isInitiallyMeal)
        }}
      >
        <TimeWidget
          autoFocusedAdjuster={autoFocusedTimeWidgetAdjuster}
          dateEditing={date}
          displayMode={
            useSettingsQueryResult.data?.timeFormat === '24_hour' ? '24' : '12'
          }
          endAtSecondsSinceMidnight={endAtSecondsSinceMidnight}
          fieldPrefix={fieldPrefix}
          onSubmit={(
            startAtSecondsSinceMidnight,
            endAtSecondsSinceMidnight,
          ) => {
            if (variant === 'time') {
              onSubmit(startAtSecondsSinceMidnight, endAtSecondsSinceMidnight)
            } else {
              onSubmit(
                startAtSecondsSinceMidnight,
                endAtSecondsSinceMidnight,
                isMeal,
              )
            }
            close()
          }}
          renderTopRight={() => {
            if (variant !== 'time-and-duration') return null
            if (!('showIsMealCheckbox' in props)) return null
            if (!props.showIsMealCheckbox) return null

            return <MealCheckbox isMeal={isMeal} onChange={setIsMeal} />
          }}
          startAtSecondsSinceMidnight={startAtSecondsSinceMidnight}
          title={
            'day' in props ? t(`common.dayOfWeek.${props.day}.full`) : undefined
          }
          timeZone={timeZone}
          variant={variant}
        />
      </TimeWidgetContainer>
      <Display contents={formattedContents} />
    </GridCell>
  )
}
