import { getLatestTaskEstimate, saveEstimate } from '@lib/api'
import { taskEstimateKeys } from '@lib/keys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useLatestTaskEstimate = (taskId: number) =>
  useQuery(taskEstimateKeys.latestForTask(taskId), () =>
    getLatestTaskEstimate(taskId),
  )

export const useSaveEstimate = (taskId: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      suppliedValue,
      suppliedValueType,
    }: {
      suppliedValue: number
      suppliedValueType: TaskEstimateSuppliedValueType
    }) => {
      return saveEstimate(taskId, { suppliedValue, suppliedValueType })
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(taskEstimateKeys.byTask(taskId))
      },
    },
  )
}
