export const calculateWidths = ({
  etc,
  logged,
}: {
  etc?: number
  logged: number
}) => {
  if (etc === undefined || (etc === 0 && logged === 0))
    return { logged: 50, etc: 50 }

  const total = etc + logged
  const loggedWidth = (logged / total) * 100
  const estimatedWidth = 100 - loggedWidth

  return { logged: loggedWidth, etc: estimatedWidth }
}
