import { useLatestTaskEstimate, useSaveEstimate } from '@hooks/useTaskEstimates'
import { toast } from '@lib/toasts'
import {
  EtcCell as Cell,
  EtcCard as Card,
  useEtcCellRef,
} from 'components/time-entry-table'
import { useTranslation } from 'react-i18next'
import { TaskEstimateMode, TaskEstimateSuppliedValueType } from 'types'

interface Props {
  rowIndex: number
  task: SelectedTask
}

export const EtcCell = ({ rowIndex, task }: Props) => {
  const { t } = useTranslation()
  const getResult = useLatestTaskEstimate(task.id)
  const saveEstimate = useSaveEstimate(task.id)
  const ref = useEtcCellRef()

  const estimated = getResult.data?.taskEstimate?.estimatedTotalTime
  const logged = getResult.data?.totalTimeLogged

  const handleSave = async (etc: number) => {
    try {
      await saveEstimate.mutateAsync({
        suppliedValue: etc,
        suppliedValueType: TaskEstimateSuppliedValueType.ETC,
      })
      toast({
        title: t('features.timeLogging.estimateSaved'),
        content: t('features.timeLogging.successfullyAddedEstimate'),
        variant: 'success',
      })
      ref.current?.clearFocus()
    } catch (e) {
      console.error(e)
      toast({
        title: t('common.error'),
        content: t('features.timeLogging.errorAddingEstimate'),
        variant: 'error',
      })
    }
  }

  const estimateMode = getResult.data?.estimateMode ?? TaskEstimateMode.DISABLED

  return (
    <Cell
      ref={ref}
      estimated={estimated}
      estimateMode={estimateMode}
      card={({ clearFocus }) => (
        <Card
          estimateMode={estimateMode}
          disableSave={saveEstimate.isLoading || getResult.isFetching}
          estimated={estimated}
          heading={task.name}
          logged={logged}
          onCancel={clearFocus}
          onSave={(value) => void handleSave(value)}
          subHeading={task.displayPath.join(' • ')}
          subtitle={task.projectIntegration?.code}
          title={task.project.name}
        />
      )}
      logged={logged}
      rowIndex={rowIndex}
      showLoading={getResult.isLoading}
      taskTotal={getResult.data?.totalTimeLogged}
    />
  )
}
