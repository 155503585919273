import { useEntriesSumByTask } from '@features/time-logging/hooks/useEntrySums'
import { ReactNode } from 'react'

export const TaskTotalById = ({
  taskId,
  children,
}: {
  taskId: number
  children: (total: number) => ReactNode
}) => {
  const total = useEntriesSumByTask(taskId)

  return <>{children(total)}</>
}
