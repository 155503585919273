import React, { useState, useRef, FocusEvent } from 'react'
import { parseInputValueToSeconds } from '@utils/entry_input'
import { secondsToHHMM } from '@utils/time'
import { twMerge } from 'tailwind-merge'

const textClasses = 'text-sm text-right leading-none text-neutral-900'
const resetClasses =
  'outline-0 rounded-none border-0 bg-transparent m-0 p-0 px-2'

// Adds/subtracts from the duration
// Returns the greater of duration or zero
const adjustDuration = (duration: number, seconds: number) => {
  return Math.max(0, duration + seconds)
}

export type saveTrigger = 'blur' | 'enter' | 'tab'

type Props = {
  className?: string
  disabled?: boolean
  placeHolder?: string
} & (
  | {
      initialValue: string
      onSave: (newDuration: number, trigger: saveTrigger) => void
      onCancel: () => void
    }
  | {
      value: string
      setValue: (value: string) => void
      onIncrement: () => void
      onDecrement: () => void
    }
)

export const EntryInput = ({
  className,
  disabled,
  placeHolder = 'hh:mm',
  ...props
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const [tempValue, setTempValue] = useState(
    'initialValue' in props ? props.initialValue ?? '' : '',
  )

  const incrementDecrementHandler = (delta: number) => {
    setTempValue(
      secondsToHHMM(adjustDuration(parseInputValueToSeconds(tempValue), delta)),
    )
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ('value' in props) {
      props.setValue(e.target.value)
    } else {
      setTempValue(e.target.value)
    }
  }

  const save = (value: string, trigger: saveTrigger) => {
    if ('value' in props) return

    // Parse input duration
    props.onSave(parseInputValueToSeconds(value.trim()), trigger)
  }

  const saveCurrentValue = (trigger: saveTrigger) => {
    save(ref.current?.value ?? '', trigger)
  }

  const blurHandler = (e: FocusEvent<HTMLInputElement>) => {
    save(e.target.value, 'blur')
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let stopPropagation = true

    switch (e.key) {
      case 'Escape':
        // Immediately cancels, without saving value
        'onCancel' in props && props.onCancel()
        break
      case 'Tab':
        // Saves and then "Tabs" (or shift+tabs)
        saveCurrentValue('tab')
        stopPropagation = false
        break
      case 'ArrowUp':
        'onIncrement' in props
          ? props.onIncrement()
          : incrementDecrementHandler(3600)
        break
      case 'ArrowDown':
        'onDecrement' in props
          ? props.onDecrement()
          : incrementDecrementHandler(-900)
        break
    }

    // Trap all key presses in input (i.e. while focused)
    // Those interactions should not impact the grid in any way
    // (e.g. the left/right arrow keys should move the cursor position within the input,
    // not change the focused grid cell)
    if (stopPropagation) e.stopPropagation()
  }

  // Save when enter key is released
  // (this helps solve a bug where the key press would be picked up here
  // and also by the cell beneath this one, causing it to enter edit mode
  // once it's focused)
  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveCurrentValue('enter')
    }
  }

  return (
    <input
      disabled={disabled}
      ref={ref}
      autoFocus={true}
      value={'value' in props ? props.value : tempValue}
      placeholder={placeHolder}
      // Note: w-0 because input elements don't respect flexbox
      // https://stackoverflow.com/questions/42421361/input-button-elements-not-shrinking-in-a-flex-container
      className={twMerge(`w-0 grow ${resetClasses} ${textClasses}`, className)}
      onChange={changeHandler}
      onBlur={blurHandler}
      onKeyDown={keyDownHandler}
      onKeyUp={keyUpHandler}
    />
  )
}
