const day = 60 * 60 * 8
const hour = 60 * 60

export const renderDaysHoursMinutes = (
  duration: number /* seconds */ | undefined,
) => {
  if (duration === undefined) return ''
  if (duration === 0) return '0d'

  const days = Math.floor(duration / day)
  const hours = Math.floor((duration % day) / hour)
  const minutes = Math.floor((duration % hour) / 60)

  const parts = []

  if (days > 0) {
    parts.push(`${days}d`)
  }

  if (hours > 0) {
    parts.push(`${hours}h`)
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`)
  }

  return parts.length === 0 ? '' : parts.join(' ')
}

export const renderDaysHours = (duration: number /* seconds */ | undefined) => {
  const roundedEstimate =
    duration === undefined ? undefined : Math.round(duration / hour) * hour

  return renderDaysHoursMinutes(roundedEstimate)
}
