import { searchAbsences, refreshAbsences } from '@lib/api'
import { leaveKeys } from '@lib/keys'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useWeekDates } from '@hooks/useWeekDates'
import { dateIntersectsAbsence } from '@utils/absence'

interface Options {
  endDateOverride?: DateTime
  startDateOverride?: DateTime
  userId: number
}

export const useAbsences = (options: Options) => {
  const { start: startDate, end: endDate } = useWeekDates()

  const start = options.startDateOverride ?? startDate
  const end = options.endDateOverride ?? endDate

  return useQuery(leaveKeys.search(options.userId, start, end), () =>
    searchAbsences(options.userId, start, end),
  )
}

export const useAbsencesForDate = (date: DateTime, options: Options) => {
  const { data } = useAbsences(options)

  if (!data) return []

  return data.filter((absence) => dateIntersectsAbsence(date, absence))
}

export const useRefreshAbsences = (userId: number, employeeId?: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      startDate,
      endDate,
    }: {
      startDate: DateTime
      endDate: DateTime
    }) => {
      if (employeeId === undefined) return

      await refreshAbsences({ employeeId, startDate, endDate })

      // This endpoint enqueues a background job but doesn't offer
      // a way to track the job — so we just wait 30s before resolving and hope for the best!
      await new Promise((resolve) => setTimeout(resolve, 30000))
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(leaveKeys.user(userId))
      },
    },
  )
}
