import {
  ApprovalGroupPicker,
  DateRange,
  FacilityPicker,
  LinkedEntityPicker,
  NumericRange,
  PipelineStepPicker,
  ProjectPicker,
  SearchQuery,
  UserPicker,
  ValuePicker,
  WorkdayDepartmentPicker,
} from 'components/tables/column-filters'
import type { ReportTypeMap, ReportFilterValue } from '../types'
import { ReactNode } from 'react'
import { TFunction } from 'react-i18next'
import {
  AuditLogTargetType,
  AuditLogEditField,
  Status,
  AuditLogEventType,
  TimeLogReportEntryType,
  ProjectIntegrationType,
} from 'types'
import { normalizeValue } from '../utils/normalizeValue'
import { secondsToHHMM } from '@utils/time'
import { DateTime } from 'luxon'
import { translationForAuditLogTargetType } from '../utils/translationForAuditLogTargetType'
import { translationForAuditLogEditField } from '../utils/translationForAuditLogEditField'
import { ProjectIntegrationPicker } from 'components/tables/column-filters/project-integration-picker'

const handleValuesChange =
  <T extends string | number>(
    cb: (arg: { values?: T[] } | undefined) => void,
  ) =>
  (selected: Set<T>) => {
    const values = normalizeValue(Array.from(selected))
    cb(values && { values })
  }

const toSet = <T,>(v: T[] | undefined): Set<T> => {
  return new Set(v ?? [])
}

type TranslationKey = Parameters<TFunction<'translation', undefined>>[0]

export interface RenderFilterOptions<V> {
  value: V
  onChange: (v: V) => void
}

class Info<
  T,
  RT extends keyof ReportTypeMap,
  FK extends keyof ReportTypeMap[RT]['filters'],
> {
  public sortKey: ReportTypeMap[RT]['pageColumn']

  constructor(
    public labelKey: TranslationKey,
    public accessorKey: ReportTypeMap[RT]['pageColumn'],
    public accessor: (
      row: ReportTypeMap[RT]['pageRow'],
      injectedHelpers: {
        t: TFunction<'translation', undefined>
        toLocaleString: (
          args: Intl.DateTimeFormatOptions,
        ) => (date: DateTime) => string
      },
    ) => T,
    public filterKey: FK,
    public renderFilter: (
      options: RenderFilterOptions<ReportFilterValue<RT, FK>>,
    ) => ReactNode,
    sortKey?: ReportTypeMap[RT]['pageColumn'],
  ) {
    this.sortKey = sortKey ?? this.accessorKey
  }
}

type InfoMap<RT extends keyof ReportTypeMap> = Record<
  ReportTypeMap[RT]['columnId'],
  Info<
    string | number | null | undefined,
    RT,
    keyof ReportTypeMap[RT]['filters']
  >
>

const infoByTimeLogColumnId: InfoMap<'time_log'> = {
  'approval-group.id': new Info(
    'features.reporting.id',
    'approvalGroup.id',
    (row) => row.approvalGroup.id,
    'approvalGroup.id',
    ({ value, onChange }) => (
      <ApprovalGroupPicker
        columnNameTranslationKey="features.reporting.timeLog.approvalGroupId"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
  ),
  'approval-group.name': new Info(
    'features.reporting.name',
    'approvalGroup.name',
    (row) => row.approvalGroup.name,
    'approvalGroup.id',
    ({ value, onChange }) => (
      <ApprovalGroupPicker
        columnNameTranslationKey="features.reporting.timeLog.approvalGroupName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
    'approvalGroup.name',
  ),
  'linked-entity.type': new Info(
    'features.reporting.type',
    'linkedEntity.type',
    (row) => row.linkedEntity.type,
    'linkedEntity.type',
    ({ value, onChange }) => (
      <LinkedEntityPicker
        columnNameTranslationKey="features.reporting.timeLog.linkedEntityType"
        onChange={handleValuesChange(onChange)}
        selected={toSet(value?.values)}
      />
    ),
  ),
  'linked-entity.name': new Info(
    'features.reporting.timeLog.nameOrCode',
    'linkedEntity.name',
    (row) => row.linkedEntity.name,
    'linkedEntity.name',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.linkedEntityNameOrCode"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'linked-entity.shotgrid-id': new Info(
    'features.reporting.id',
    'linkedEntity.shotgridId',
    (row) => row.linkedEntity.shotgridId,
    'linkedEntity.shotgridId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.linkedEntityShotgridId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'project-integration.id': new Info(
    'features.reporting.id',
    'projectIntegration.id',
    (row) => row.projectIntegration.id,
    'projectIntegration.id',
    ({ value, onChange }) => (
      <ProjectIntegrationPicker
        columnNameTranslationKey="common.projectIntegration"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
  ),
  'project-integration.name': new Info(
    'features.reporting.name',
    'projectIntegration.name',
    (row) => row.projectIntegration.name,
    'projectIntegration.id',
    ({ value, onChange }) => (
      <ProjectIntegrationPicker
        columnNameTranslationKey="common.projectIntegration"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
    'project.name',
  ),
  'project-integration.type': new Info(
    'features.reporting.type',
    'projectIntegration.type',
    (row) => row.projectIntegration.type,
    'projectIntegration.type',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.type"
        onChange={(selected) => {
          const types = normalizeValue(Array.from(selected))
          onChange(types && { values: types })
        }}
        renderLabel={(type, t) => t(`projectIntegrationTypes.${type}`)}
        selected={new Set(value?.values)}
        values={Object.values(ProjectIntegrationType)}
      />
    ),
  ),
  'project.id': new Info(
    'features.reporting.timeLog.timeLoggerId',
    'project.id',
    (row) => row.project.id,
    'project.id',
    ({ value, onChange }) => (
      <ProjectPicker
        columnNameTranslationKey="features.reporting.timeLog.timeLoggerProject"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
  ),
  'project.source-id': new Info(
    'features.reporting.timeLog.sourceId',
    'project.sourceId',
    (row) => row.project.sourceId,
    'project.sourceId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.sourceId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'project.name': new Info(
    'features.reporting.name',
    'project.name',
    (row) => row.project.name,
    'project.id',
    ({ value, onChange }) => (
      <ProjectPicker
        columnNameTranslationKey="features.reporting.timeLog.timeLoggerProject"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
    'project.name',
  ),
  'project.workday-id': new Info(
    'features.reporting.timeLog.workdayId',
    'project.workdayId',
    (row) => row.project.workdayId,
    'project.workdayId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.projectWorkdayID"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'time-log.date': new Info(
    'features.reporting.date',
    'date',
    (row, { toLocaleString }) => toLocaleString(DateTime.DATE_SHORT)(row.date),
    'date',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'linked-entity.sequence-code': new Info(
    'features.reporting.timeLog.sequenceCode',
    'linkedEntity.sequence.code',
    (row) => row.linkedEntity.sequence?.code,
    'linkedEntity.sequence.code',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.linkedEntitySequenceCode"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'pipeline-step.code': new Info(
    'common.code',
    'pipelineStep.code',
    (row) => row.pipelineStep.code,
    'pipelineStep.id',
    ({ value, onChange }) => (
      <PipelineStepPicker
        columnNameTranslationKey="common.code"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
    'pipelineStep.code',
  ),
  'facility.name': new Info(
    'features.reporting.name',
    'facility.name',
    (row) => row.facility.name,
    'facility.id',
    ({ value, onChange }) => (
      <FacilityPicker
        columnNameTranslationKey="features.reporting.facilityName"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
    'facility.name',
  ),
  'user.workday-worker-id': new Info(
    'features.reporting.timeLog.wid',
    'employee.workdayWorkerId',
    (row) => row.employee.workdayWorkerId,
    'employee.workdayWorkerId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.workdayWorkerId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  // 'time-log.most-recent-approval-datetime': new Info(
  //   'features.reporting.timeLog.mostRecentApproval',
  //   'mostRecentApprovalTimestamp',
  //   ({ mostRecentApprovalTimestamp }, { toLocaleString }) =>
  //     mostRecentApprovalTimestamp &&
  //     toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)(
  //       mostRecentApprovalTimestamp,
  //     ),
  //   'mostRecentApprovalTimestamp',
  //   ({ value, onChange }) => (
  //     <DateRange
  //       endDate={value?.end}
  //       startDate={value?.start}
  //       relativeDateRangeValue={value?.relativeDateRange}
  //       onChange={({ startDate, endDate, relativeDateRangeValue }) =>
  //         onChange(
  //           normalizeValue({
  //             start: startDate,
  //             end: endDate,
  //             relativeDateRange: relativeDateRangeValue,
  //           }),
  //         )
  //       }
  //     />
  //   ),
  // ),
  // 'time-card.submission-datetime': new Info(
  //   'features.reporting.timeLog.timeCardSubmittedAt',
  //   'timeCardSubmissionTimestamp',
  //   ({ timeCardSubmissionTimestamp }, { toLocaleString }) =>
  //     timeCardSubmissionTimestamp &&
  //     toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)(
  //       timeCardSubmissionTimestamp,
  //     ),
  //   'timeCardSubmissionTimestamp',
  //   ({ value, onChange }) => (
  //     <DateRange
  //       endDate={value?.end}
  //       startDate={value?.start}
  //       relativeDateRangeValue={value?.relativeDateRange}
  //       onChange={({ startDate, endDate, relativeDateRangeValue }) =>
  //         onChange(
  //           normalizeValue({
  //             start: startDate,
  //             end: endDate,
  //             relativeDateRange: relativeDateRangeValue,
  //           }),
  //         )
  //       }
  //     />
  //   ),
  // ),
  'time-card.sent-to-workday-datetime': new Info(
    'features.reporting.sentToWorkday',
    'sentToWorkdayTimestamp',
    (row, { toLocaleString }) =>
      row.sentToWorkdayTimestamp &&
      toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)(
        row.sentToWorkdayTimestamp,
      ),
    'sentToWorkdayTimestamp',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'time-log.duration': new Info(
    'features.reporting.timeLog.duration',
    'durationInSeconds',
    (row) => secondsToHHMM(row.durationInSeconds),
    'durationInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.duration"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.work-duration': new Info(
    'features.reporting.timeLog.workDuration',
    'workDurationInSeconds',
    (row) =>
      row.workDurationInSeconds && secondsToHHMM(row.workDurationInSeconds),
    'workDurationInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.workDuration"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.straight-time': new Info(
    'features.reporting.timeLog.straightTimeAbbr',
    'straightTimeInSeconds',
    (row) =>
      row.straightTimeInSeconds && secondsToHHMM(row.straightTimeInSeconds),
    'straightTimeInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.straightTime"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.overtime': new Info(
    'features.reporting.timeLog.overtimeAbbr',
    'overtimeInSeconds',
    (row) => row.overtimeInSeconds && secondsToHHMM(row.overtimeInSeconds),
    'overtimeInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.overtime"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.double-time': new Info(
    'features.reporting.timeLog.doubleTimeAbbr',
    'doubleTimeInSeconds',
    (row) => row.doubleTimeInSeconds && secondsToHHMM(row.doubleTimeInSeconds),
    'doubleTimeInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.doubleTime"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.toil': new Info(
    'features.reporting.timeLog.toil',
    'toilInSeconds',
    (row) => row.toilInSeconds && secondsToHHMM(row.toilInSeconds),
    'toilInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.toil"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.toil1_5x': new Info(
    'features.reporting.timeLog.toil1_5x',
    'toil_1_5xInSeconds',
    (row) => row.toil_1_5xInSeconds && secondsToHHMM(row.toil_1_5xInSeconds),
    'toil_1_5xInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.toil1_5x"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.time-off-duration': new Info(
    'features.reporting.timeLog.timeOffDuration',
    'timeOffInSeconds',
    (row) => row.timeOffInSeconds && secondsToHHMM(row.timeOffInSeconds),
    'timeOffInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.timeOffDuration"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.leave-duration': new Info(
    'features.reporting.timeLog.leaveDuration',
    'leaveInSeconds',
    (row) => row.leaveInSeconds && secondsToHHMM(row.leaveInSeconds),
    'leaveInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.leaveDuration"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  'time-log.holiday-duration': new Info(
    'features.reporting.timeLog.holiday',
    'holidayInSeconds',
    (row) => row.holidayInSeconds && secondsToHHMM(row.holidayInSeconds),
    'holidayInSeconds',
    ({ value, onChange }) => (
      <NumericRange
        columnNameTranslationKey="features.reporting.timeLog.holiday"
        from={value?.numericRangeFrom}
        to={value?.numericRangeTo}
        onChange={(value) =>
          onChange(
            normalizeValue({
              numericRangeFrom: value.from,
              numericRangeTo: value.to,
            }),
          )
        }
      />
    ),
  ),
  // 'task.shotgrid-status': new Info(
  //   'features.reporting.timeLog.shotgridStatus',
  //   'task.shotgridStatus',
  //   (row) => row.task.shotgridStatus,
  //   'task.shotgridStatus',
  //   ({ value, onChange }) => (
  //     <input
  //       value={value?.values?.toString() ?? ''}
  //       onChange={(_event) => handleValuesChange(onChange)(new Set())}
  //     />
  //   ),
  // ),
  'time-log.task-source-id': new Info(
    'features.reporting.timeLog.taskSourceId',
    'taskSourceTimeLogId',
    (row) => row.taskSourceTimeLogId,
    'taskSourceTimeLogId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.taskSourceTimeLogId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'user.task-source-id': new Info(
    'features.reporting.timeLog.taskSourceId',
    'user.taskSourceUserId',
    (row) => row.user.taskSourceUserId,
    'user.taskSourceUserId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.taskSourceId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'task.name': new Info(
    'features.reporting.name',
    'task.name',
    (row) => row.task.name,
    'task.name',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.taskName"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'task.id': new Info(
    'features.reporting.timeLog.timeLoggerId',
    'task.id',
    (row) => row.task.id,
    'task.id',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.timeLoggerTaskId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'task.source-id': new Info(
    'features.reporting.timeLog.sourceId',
    'task.sourceId',
    (row) => row.task.sourceId,
    'task.sourceId',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.sourceId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'task.sg-shot-code': new Info(
    'features.reporting.timeLog.sgShot',
    'task.sgShot.code',
    (row) => row.task.sgShot.code,
    'task.sgShot.code',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.sgShot"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'time-log.status': new Info(
    'features.reporting.timeLog.status',
    'status',
    (row, { t }) =>
      row.status && (t ? t(`common.statuses.${row.status}`) : row.status),
    'status',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.timeLog.timeLogStatus"
        onChange={(selected) => {
          const statuses = normalizeValue(Array.from(selected))
          onChange(statuses && { statuses })
        }}
        renderLabel={(status, t) => t(`common.statuses.${status}`)}
        selected={new Set(value?.statuses)}
        values={[Status.Open, Status.Submitted, Status.Approved, Status.Sent]}
      />
    ),
  ),
  'time-log.created-at': new Info(
    'features.reporting.timeLog.createdAt',
    'createdAt',
    ({ createdAt }, { toLocaleString }) =>
      createdAt &&
      toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)(createdAt),
    'createdAt',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'time-log.updated-at': new Info(
    'features.reporting.timeLog.updatedAt',
    'updatedAt',
    ({ updatedAt }, { toLocaleString }) =>
      updatedAt &&
      toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)(updatedAt),
    'updatedAt',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'user.name': new Info(
    'features.reporting.name',
    'user.name',
    (row) => row.user.name,
    'user.id',
    ({ value, onChange }) => (
      <UserPicker
        columnNameTranslationKey="features.reporting.userName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
    'user.name',
  ),
  'user.email': new Info(
    'features.reporting.email',
    'user.email',
    (row) => row.user.email,
    'user.email',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.userEmail"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  // 'workday.location-name': new Info(
  //   'features.reporting.timeLog.location',
  //   'workday.location.name',
  //   (row) => row.workday.location.name,
  //   'workday.location.name',
  //   ({ value, onChange }) => (
  //     <SearchQuery
  //       columnNameTranslationKey="features.reporting.timeLog.workdayLocation"
  //       query={value?.query}
  //       onChange={(query) => onChange(normalizeValue({ query }))}
  //     />
  //   ),
  // ),
  'department.name': new Info(
    'features.reporting.name',
    'workday.department.name',
    (row) => row.workday.department.name,
    'workday.department.id',
    ({ value, onChange }) => (
      <WorkdayDepartmentPicker
        columnNameTranslationKey="features.reporting.timeLog.departmentName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
    'workday.department.name',
  ),

  'time-log.type': new Info(
    'features.reporting.type',
    'type',
    (row, { t }) =>
      t ? t(`features.reporting.timeLog.types.${row.type}`) : row.type,
    'type',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.type"
        onChange={(selected) => {
          const types = normalizeValue(Array.from(selected))
          onChange(types && { values: types })
        }}
        renderLabel={(type, t) => t(`features.reporting.timeLog.types.${type}`)}
        selected={new Set(value?.values)}
        values={Object.values(TimeLogReportEntryType)}
      />
    ),
  ),
  'absence.workday-type': new Info(
    'features.reporting.timeLog.workdayType',
    'workday.absenceType',
    (row) => row.workday.absenceType,
    'workday.absenceType',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.workdayType"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'holiday.name': new Info(
    'features.reporting.name',
    'workday.holiday.name',
    (row) => row.workday.holiday.name,
    'workday.holiday.name',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.filterColumns.workday.holiday.name"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'note.body': new Info(
    'features.reporting.timeLog.bodyNote',
    'note.body',
    (row) => row.note.body,
    'note.body',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.filterColumns.note.body"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
}

const infoByAuditLogColumnId: InfoMap<'audit_log'> = {
  'event.type': new Info(
    'features.reporting.type',
    'eventType',
    (row, { t }) =>
      t(`features.reporting.auditLog.auditLogEventTypes.${row.eventType}`),
    'eventType',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.auditLog.eventType"
        onChange={(selected) => {
          const types = normalizeValue(Array.from(selected))
          onChange(types && { values: types })
        }}
        renderLabel={(type, t) =>
          t(`features.reporting.auditLog.auditLogEventTypes.${type}`)
        }
        selected={new Set(value?.values)}
        values={Object.values(AuditLogEventType)}
        searchable={true}
      />
    ),
  ),
  'event.datetime': new Info(
    'features.reporting.auditLog.datetime',
    'datetime',
    (row, { toLocaleString }) =>
      toLocaleString(DateTime.DATETIME_SHORT)(row.datetime),
    'datetime',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'actor.id': new Info(
    'features.reporting.id',
    'actorUser.id',
    (row) => row.actorUser.id,
    'actorUser.id',
    ({ value, onChange }) => (
      <UserPicker
        columnNameTranslationKey="features.reporting.userName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
  ),
  'actor.name': new Info(
    'features.reporting.name',
    'actorUser.name',
    (row) => row.actorUser.name,
    'actorUser.id',
    ({ value, onChange }) => (
      <UserPicker
        columnNameTranslationKey="features.reporting.userName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
  ),
  'actor.email': new Info(
    'features.reporting.email',
    'actorUser.email',
    (row) => row.actorUser.email,
    'actorUser.email',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.email"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'target-entity.type': new Info(
    'features.reporting.type',
    'target.type',
    (row, { t }) => t(translationForAuditLogTargetType(row.target.type)),
    'target.type',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.entityType"
        onChange={(selected) => {
          const types = normalizeValue(Array.from(selected))
          onChange(types && { values: types })
        }}
        renderLabel={(type, t) => t(translationForAuditLogTargetType(type))}
        selected={new Set(value?.values)}
        values={Object.values(AuditLogTargetType)}
      />
    ),
  ),
  'target-entity.id': new Info(
    'features.reporting.id',
    'target.id',
    (row) => row.target.id,
    'target.id',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.timeLog.entityId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'target-entity.name': new Info(
    'features.reporting.name',
    'target.name',
    (row) => row.target.name,
    'target.name',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.entityName"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'target-user.id': new Info(
    'features.reporting.id',
    'targetUser.id',
    (row) => row.targetUser.id,
    'targetUser.id',
    ({ value, onChange }) => (
      <UserPicker
        columnNameTranslationKey="features.reporting.userName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
  ),
  'target-user.name': new Info(
    'features.reporting.name',
    'targetUser.name',
    (row) => row.targetUser.name,
    'targetUser.id',
    ({ value, onChange }) => (
      <UserPicker
        columnNameTranslationKey="features.reporting.userName"
        onChange={handleValuesChange(onChange)}
        selectedIds={toSet(value?.values)}
      />
    ),
  ),
  'target-user.email': new Info(
    'features.reporting.email',
    'targetUser.email',
    (row) => row.targetUser.email,
    'targetUser.email',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.entityName"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'target.date': new Info(
    'features.reporting.date',
    'targetDate',
    (row, { toLocaleString }) =>
      row.targetDate && toLocaleString(DateTime.DATE_SHORT)(row.targetDate),
    'targetDate',
    ({ value, onChange }) => (
      <DateRange
        endDate={value?.end}
        startDate={value?.start}
        relativeDateRangeValue={value?.relativeDateRange}
        onChange={({ startDate, endDate, relativeDateRangeValue }) =>
          onChange(
            normalizeValue({
              start: startDate,
              end: endDate,
              relativeDateRange: relativeDateRangeValue,
            }),
          )
        }
      />
    ),
  ),
  'field-edited.name': new Info(
    'features.reporting.name',
    'fieldEdited.name',
    (row, { t }) =>
      row.fieldEdited.name &&
      t(translationForAuditLogEditField(row.fieldEdited.name)),
    'fieldEdited.name',
    ({ value, onChange }) => (
      <ValuePicker
        accessor={(v) => v}
        columnNameTranslationKey="features.reporting.auditLog.fieldName"
        onChange={(selected) => {
          const fields = normalizeValue(Array.from(selected))
          onChange(fields && { values: fields })
        }}
        renderLabel={(type, t) => t(translationForAuditLogEditField(type))}
        selected={new Set(value?.values)}
        values={Object.values(AuditLogEditField)}
      />
    ),
  ),
  'field-edited.old-value': new Info(
    'features.reporting.auditLog.oldValue',
    'fieldEditedFromValue.text',
    (row) => row.fieldEditedFromValue.text,
    'fieldEditedFromValue.text',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.auditLog.oldValue"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'field-edited.old-id': new Info(
    'features.reporting.auditLog.oldId',
    'fieldEditedFromValue.id',
    (row) => row.fieldEditedFromValue.id,
    'fieldEditedFromValue.id',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.auditLog.oldId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'field-edited.new-value': new Info(
    'features.reporting.auditLog.newValue',
    'fieldEditedToValue.text',
    (row) => row.fieldEditedToValue.text,
    'fieldEditedToValue.text',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.auditLog.newValue"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'field-edited.new-id': new Info(
    'features.reporting.auditLog.newId',
    'fieldEditedToValue.id',
    (row) => row.fieldEditedToValue.id,
    'fieldEditedToValue.id',
    ({ value, onChange }) => (
      <SearchQuery
        columnNameTranslationKey="features.reporting.auditLog.newId"
        query={value?.query}
        onChange={(query) => onChange(normalizeValue({ query }))}
      />
    ),
  ),
  'facility.id': new Info(
    'features.reporting.id',
    'facility.id',
    (row) => row.facility.id,
    'facility.id',
    ({ value, onChange }) => (
      <FacilityPicker
        columnNameTranslationKey="features.reporting.facilityName"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
  ),
  'facility.name': new Info(
    'features.reporting.name',
    'facility.name',
    (row) => row.facility.name,
    'facility.id',
    ({ value, onChange }) => (
      <FacilityPicker
        columnNameTranslationKey="features.reporting.facilityName"
        selectedIds={toSet(value?.values)}
        onChange={handleValuesChange(onChange)}
      />
    ),
  ),
}

export const columnInfoFor = <RT extends keyof ReportTypeMap>(
  reportType: RT,
) => {
  if (reportType === 'time_log') {
    return infoByTimeLogColumnId
  }

  if (reportType === 'audit_log') {
    return infoByAuditLogColumnId
  }

  // The eslint rule is smarter than TS in this instance :)
  // If we remove this throw, TS claims this function can return undefined,
  // which it absolutely cannot based on the restrictive reportType.
  // However, when we add this throw, eslint then (correctly)
  // complains that reportType is type never!
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Unsupported reportType: ${reportType}`)
}

export const infoByColumnId = <RT extends keyof ReportTypeMap>({
  reportType,
  id,
}: {
  reportType: RT
  id: ReportTypeMap[RT]['columnId']
}) => (columnInfoFor(reportType) as InfoMap<RT>)[id]
