import { SideColumn } from 'components/time-entry-table'
import { TaskTotalCell } from './cells/TaskTotalCell'
import { TaskTotalById } from './TaskTotalById'

interface Props {
  tasks: { id: number }[]
}

export function TaskTotalColumn({ tasks }: Props) {
  return (
    <SideColumn side="right">
      {tasks.map((task, index) => (
        <TaskTotalById key={`TotalByTask${task.id}`} taskId={task.id}>
          {(total) => <TaskTotalCell row={index + 1} total={total} />}
        </TaskTotalById>
      ))}
    </SideColumn>
  )
}
