// TIL: regex objects are stateful if you add the global flag
const day = /(\d+\.*\d*)\s*d(ay)*/im
const hour = /(\d+\.*\d*)\s*h(our)*/im
const minute = /(\d+\.*\d*)\s*m(minute)*/im

const parseAs =
  ({ reg, multiplyBy }: { reg: RegExp; multiplyBy: number }) =>
  (value: string) => {
    const [_, numStr] = reg.exec(value) ?? []

    if (numStr === undefined) return undefined
    const parsed = Number(numStr)

    if (isNaN(parsed)) return undefined

    return parsed * multiplyBy
  }

const parseAsDay = parseAs({ reg: day, multiplyBy: 8 * 60 * 60 })
const parseAsHour = parseAs({ reg: hour, multiplyBy: 60 * 60 })
const parseAsMinute = parseAs({ reg: minute, multiplyBy: 60 })
const operations = [parseAsDay, parseAsHour, parseAsMinute]

export const parse = (value?: string): number | undefined => {
  const v = value?.trim() ?? ''

  if (v === '') return undefined

  const num = Number(v)
  if (!isNaN(num)) return num * 60 * 60 * 8

  let seconds = 0
  let isValid = false

  operations.forEach((op) => {
    const parsed = op(v)
    if (parsed === undefined) return

    isValid = true
    seconds += parsed
  })

  return isValid ? seconds : undefined
}
