import { ReportTypeMap } from '../types'

// All time log report columns should be displayed by default
const defaultTimeLogColumnIds: ReportTypeMap['time_log']['columnId'][] = [
  'time-log.date',
  'time-log.type',
  'time-log.duration',
  'time-log.work-duration',
  'time-log.straight-time',
  'time-log.overtime',
  'time-log.double-time',
  'time-log.toil',
  'time-log.toil1_5x',
  'time-log.time-off-duration',
  'time-log.leave-duration',
  'time-log.holiday-duration',
  'time-log.status',
  'time-log.task-source-id',
  'time-log.created-at',
  'time-log.updated-at',
  'facility.name',
  'department.name',
  'project-integration.type',
  'project-integration.name',
  'project.id',
  'project.name',
  'project.source-id',
  'project.workday-id',
  'linked-entity.type',
  'linked-entity.shotgrid-id',
  'linked-entity.name',
  'linked-entity.sequence-code',
  'pipeline-step.code',
  'task.id',
  'task.source-id',
  'task.name',
  'task.sg-shot-code',
  'user.name',
  'user.email',
  'user.workday-worker-id',
  'user.task-source-id',
  'approval-group.id',
  'approval-group.name',
  'absence.workday-type',
  'holiday.name',
  'time-card.sent-to-workday-datetime',
]

// A subset of audit log report columns should be displayed by default
const defaultAuditLogColumnIds: ReportTypeMap['audit_log']['columnId'][] = [
  'event.datetime',
  'event.type',
  'actor.name',
  'target-entity.type',
  'target-entity.name',
  'target-user.id',
  'target-user.name',
  'target.date',
  'facility.name',
]

export const defaultColumnIds = <RT extends keyof ReportTypeMap>(
  reportType: RT,
): ReportTypeMap[RT]['columnId'][] => {
  if (reportType === 'time_log') {
    return defaultTimeLogColumnIds
  }

  if (reportType === 'audit_log') {
    return defaultAuditLogColumnIds
  }

  return []
}
