import { ReportTypeMap } from '@features/reporting/types'
import { DateTime } from 'luxon'
import { GetReportPageOptions } from './api'

export const breakKeys = {
  all: ['breaks'] as const,
  list: (userId: number, dateRange: DateTime[]) =>
    [...breakKeys.all, userId, dateRange] as const,
}

export const entryKeys = {
  all: ['entries'] as const,
  byTimeCard: (timeCardId: number) => [...entryKeys.all, { timeCardId }],
  search: (userId: number, start: DateTime, end: DateTime) =>
    [...entryKeys.all, { userId, start, end }] as const,
  mutate: (userId: number, start: DateTime, end: DateTime, entryId: number) =>
    [...entryKeys.search(userId, start, end), { entryId }] as const,
}

export const taskKeys = {
  all: ['tasks'],
  assigned: (userId: number) => [...taskKeys.all, 'assigned', { userId }],
  selected: (userId: number, startDate: DateTime, endDate: DateTime) => [
    ...taskKeys.all,
    { userId, startDate, endDate },
  ],
  select: (
    userId: number,
    taskId: number,
    startDate: DateTime,
    endDate: DateTime,
  ) => [...taskKeys.selected(userId, startDate, endDate), { taskId }],
  nonProject: ['tasks', 'nonProject'],
}

export const searchTasksKeys = {
  all: ['search-tasks'],
  unscoped: (options: MultisearchOptions) => [...searchTasksKeys.all, options],
  search: (options: SearchTaskOptions) => [...searchTasksKeys.all, options],
  searchExplore: (options: SearchExploreTaskOptions) => [
    ...searchTasksKeys.all,
    options,
  ],
  searchOld: (options: SearchTaskOptionsOld) => [
    ...searchTasksKeys.all,
    options,
  ],
}

export const timeCardKeys = {
  all: ['timeCards'],
  user: (userId: number) => [...timeCardKeys.all, { userId }],
  adminSearch: (start: DateTime, end: DateTime) => [
    ...timeCardKeys.all,
    { start, end },
  ],
  search: (userId: number, start: DateTime, end: DateTime) => [
    ...timeCardKeys.user(userId),
    { start, end },
  ],
}

export const leaveKeys = {
  all: ['leave'],
  user: (userId: number) => [...leaveKeys.all, { userId }],
  search: (userId: number, startDate: DateTime, endDate: DateTime) => [
    ...leaveKeys.user(userId),
    { startDate, endDate },
  ],
}

export const holidayKeys = {
  all: ['holidays'],
  search: (calendarId: string, startDate: DateTime, endDate: DateTime) => [
    ...holidayKeys.all,
    { calendarId, startDate, endDate },
  ],
}

export const payPeriodKeys = {
  all: ['payPeriods'],
  search: (start: DateTime, end: DateTime) => [
    ...payPeriodKeys.all,
    { start, end },
  ],
}

export const settingsKeys = {
  all: ['settings'],
  global: (userId: number) => [...settingsKeys.all, 'global', userId],
}

export const projectsKeys = {
  all: ['projects'],
}

export const adminProjectsKeys = {
  all: ['admin/projects'],
}

export const pipelineStepsKeys = {
  all: ['pipelineSteps'],
}

export const departmentsKeys = {
  all: ['departments'],
}

export const shotgridEntitySchemasKeys = {
  all: ['shotgridEntitySchemas'],
}

export const employeeKeys = {
  all: ['employee'],
  search: (start: DateTime, end: DateTime) => [
    ...employeeKeys.all,
    { start, end },
  ],
  get: (userId: number, start: DateTime) => [
    ...employeeKeys.all,
    { userId, start },
  ],
}

export const usersKeys = {
  all: ['users'],
}

export const approvalDomainsInPayPeriod = {
  all: ['approval_domains_by_pay_period'],
  byPayPeriod: (payPeriodId: number) => [
    ...approvalDomainsInPayPeriod.all,
    { payPeriodId },
  ],
}

export const approvalKeys = {
  all: ['approvals'],
  search: (start: DateTime, end: DateTime) => [
    ...approvalKeys.all,
    { start, end },
  ],
}

export const approvalGroupsKeys = {
  all: ['approval_groups'],
}

export const adminApprovalGroupsKeys = {
  all: ['admin/approval_groups'],
}

export const employeesKeys = {
  all: ['employees'],
  searchInApprovalDomain: (
    approvalDomainId: string,
    start: DateTime,
    end: DateTime,
  ) => [...employeesKeys.all, { approvalDomainId, start, end }],
}

export const notesKeys = {
  all: ['notes'],
  search: (employeeId: number, start: DateTime, end: DateTime) => [
    ...notesKeys.all,
    { employeeId, start, end },
  ],
}

export const taggedTimeKeys = {
  all: ['tagged_time'],
  getApprovalTaggedTime: (approvalId: number) => [
    ...taggedTimeKeys.all,
    { approvalId },
  ],
  getTimeCardTaggedTime: (timeCardId: number) => [
    ...taggedTimeKeys.all,
    { timeCardId },
  ],
}

export const workdayTimeBlockErrorKeys = {
  all: ['workday_time_block_errors'],
  getByTimeCardId: (timeCardId: number) => [
    ...workdayTimeBlockErrorKeys.all,
    { timeCardId },
  ],
}

export const employeeDayEventLogKeys = {
  all: ['employee_day_event_log'],
  getByEmployeeAndDate: (employeeId: number, date: DateTime) => [
    ...employeeDayEventLogKeys.all,
    { employeeId, date },
  ],
}

export const approvalEventLogKeys = {
  all: ['approval_event_log'],
  get: (approvalId: number) => [...approvalEventLogKeys.all, { approvalId }],
}

export const reportDataKeys = <RT extends keyof ReportTypeMap>(
  reportType: RT,
) => ({
  all: [`${reportType}_report_data`],
  getPage: (options: GetReportPageOptions<RT>) => [
    ...reportDataKeys(reportType).all,
    { options },
  ],
})

export const facilitiesKeys = {
  all: ['facilities'],
}

export const dayLockKeys = {
  all: ['dayLocks'],
  searchByUser: (userId: number, start: DateTime, end: DateTime) => [
    ...dayLockKeys.all,
    { userId, start, end },
  ],
  searchAll: (start: DateTime, end: DateTime) => [
    ...dayLockKeys.all,
    { start, end },
  ],
}

export const savedReportKeys = {
  all: ['savedReports'],
  get: (id: number) => [...savedReportKeys.all, { id }],
}

export const facilityTimeCardStatusTotalsKeys = {
  all: 'facilityTimeCardStatusTotalsKeys',
  get: (start: DateTime, end: DateTime) => [
    facilityTimeCardStatusTotalsKeys.all,
    { start, end },
  ],
}

export const projectIntegrationsKeys = {
  all: ['projectIntegrations'],
  linkedToProject: (projectId: number) => [
    ...projectIntegrationsKeys.all,
    { projectId },
  ],
}

export const multiSearchKeys = {
  all: ['multiSearch'],
  search: (options: MultisearchOptions) => [...multiSearchKeys.all, options],
  searchByType: (options: MultisearchByTypeOptions) => [
    ...multiSearchKeys.all,
    options,
  ],
}

export const sourceProjectsKeys = {
  all: ['sourceProjects'],
  get: (projectIntegrationId: number) => [
    ...sourceProjectsKeys.all,
    { projectIntegrationId },
  ],
}

export const workdayProjectsKeys = {
  all: ['workdayProjects'],
}

export const adminManagedGroupsKeys = {
  all: ['adminManagedGroups'],
  get: (adminManagedGroupId: number) => [
    ...adminManagedGroupsKeys.all,
    { adminManagedGroupId },
  ],
}

export const taskEstimateKeys = {
  all: ['taskEstimates'],
  byTask: (taskId: number) => [...taskEstimateKeys.all, { taskId }],
  latestForTask: (taskId: number) => [
    ...taskEstimateKeys.all,
    { taskId },
    'latest',
  ],
}
