import { TaskTotal } from './TaskTotal'

interface Props {
  total: number
  row: number
}

export function TaskTotalCell(props: Props) {
  return (
    <div
      className="flex items-center justify-end col-start-totalByTask border-t border-dashed border-neutral-300 first:border-t-0"
      style={{ gridRowStart: props.row }}
    >
      <TaskTotal total={props.total} />
    </div>
  )
}
